



















































































































































































































































































































































































import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  alphaNum,
  maxLength, maxValue,
  minLength,
  minValue,
  numeric,
  required,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/PublicVoucherController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import VoucherTextInput from '../../components/VoucherTextInput/index.vue'
import VoucherDropdown from '../../components/VoucherDropdown/index.vue'
import VoucherDatePicker from '../../components/VoucherDatePicker/index.vue'
import { PublicVoucher, PublicVoucherCodeMultipleExternalPartner } from '@/domain/entities/PublicVoucher'
import * as constantData from '@/app/infrastructures/misc/Constants/publicVoucher'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import { Utils } from '@/app/infrastructures/misc'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import TimePicker from '@/app/ui/components/TimePicker/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import { IFormCreatePublicVoucher } from '@/data/infrastructures/misc/interfaces/publicVoucher'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface';

interface validationInterface {
  form: {
    voucherName: {
      required: () => ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    }
    voucherCode: {
      required: () => boolean
      minLength: ValidationRule
      alphaNum: () => ValidationRule
    }
    voucherLimit: {
      required: () => ValidationRule
      numeric: () => ValidationRule
      minValue: ValidationRule
    }
    voucherPerUser: {
      required: () => ValidationRule
      numeric: () => ValidationRule
      minValue: ValidationRule
    }
    voucherPurpose: {
      required: () => ValidationRule
    }
    voucherPointAmount: {
      required: () => boolean
      minValue: ValidationRule
    }
    discountPercentage: {
      required: () => boolean
      minValue: ValidationRule
      maxValue: ((validation: unknown) => boolean)
    }
    maxDiscountAmount: {
      required: () => boolean
      minValue: ValidationRule
    }
    minTransaction: {
      required: () => boolean
      minValue: ValidationRule
    }
    shipmentTypes: {
      required: () => boolean
      minLength: ValidationRule
    }
    redeemStartDate: {
      required: () => ValidationRule
    }
    redeemEndDate: {
      required: () => ValidationRule
    }
    voucherExpiryDate: {
      required: () => boolean
    }
    redeemStartTime: {
      required: () => ValidationRule
    }
    redeemEndTime: {
      required: () => ValidationRule
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    RadioButton,
    Badge,
    TimePicker, DateTimePickerV2,
    ModalAction,
    Button,
    Modal,
    VoucherTextInput,
    VoucherDropdown,
    VoucherDatePicker,
    OptionBox,
    LoadingOverlay,
  },
})
export default class UpdatePublicVoucher extends Vue {
  controller = controller
  constantData = constantData
  maxCount = 30
  form: IFormCreatePublicVoucher = {
    voucherName: '',
    voucherCode: '',
    voucherLimit: 1,
    voucherPerUser: 1,
    voucherPurpose: undefined,
    voucherPointAmount: 0,
    discountPercentage: 1,
    maxDiscountAmount: 1,
    minTransaction: 1,
    shipmentTypes: [],
    voucherExpiry: 1,
    redeemStartDate: undefined,
    redeemEndDate: undefined,
    voucherExpiryDate: undefined,
    redeemStartTime: undefined,
    redeemEndTime: undefined,
    voucherCodeType: constantData.EnumVoucherCodeType.SINGLE,
    voucherCodeMultipleType: constantData.EnumVoucherCodeMultipleType.INTERNAL,
    voucherCodeMultipleCount: 1,
    voucherCodeMultiplePartner: [],
  }
  multipleVoucherCodePartnerOptions: DropDownLabelValue<string>[] = []
  multipleVoucherCodePartner: string | undefined = ''
  successModal = false
  confirmationModal = false
  todayDate = dayjs().format('YYYY-MM-DD')
  hasChanged = {
    voucherName: false,
    voucherCode: false,
    voucherLimit: false,
    voucherPerUser: false,
    voucherPurpose: false,
    voucherPointAmount: false,
    discountPercentage: false,
    maxDiscountAmount: false,
    minTransaction: false,
    shipmentTypes: false,
    voucherExpiry: false,
    redeemStartDate: false,
    redeemEndDate: false,
    voucherExpiryDate: false,
    redeemStartTime: false,
    redeemEndTime: false,
  }
  disabledDate = false
  disabledTime = false

  created(): void {
    controller.getPublicVoucherDetail(this.$route.params.id)
    controller.getVoucherCodeMultipleExternalPartner()
  }

  @Validations()
  validations(): validationInterface {
    return {
      form: {
        voucherName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(this.maxCount),
        },
        voucherCode: {
          required: requiredIf(() => !this.isMultipleVoucher),
          minLength: minLength(!this.isMultipleVoucher ? 3 : 0),
          alphaNum
        },
        voucherLimit: {required, numeric, minValue: minValue(1)},
        voucherPerUser: {required, numeric, minValue: minValue(1)},
        voucherPurpose: {required},
        voucherPointAmount: {
          required: requiredIf(() => this.isParcelPoint),
          minValue: minValue(this.isParcelPoint ? 1 : 0),
        },
        discountPercentage: {
          required: requiredIf(() => this.isDiscountPercentage),
          minValue: minValue(this.isDiscountPercentage ? 1 : 0),
          maxValue: (validation: unknown) => {
            if (this.isDiscountPercentage) {
              return maxValue(100)(validation)
            }
            return true
          },
        },
        maxDiscountAmount: {
          required: requiredIf(() => this.isDiscountPercentage),
          minValue: minValue(this.isDiscountPercentage ? 1 : 0),
        },
        minTransaction: {
          required: requiredIf(() => this.isDiscountPercentage),
          minValue: minValue(this.isDiscountPercentage ? 1 : 0),
        },
        shipmentTypes: {
          required: requiredIf(() => this.isDiscountPercentage),
          minLength: minLength(this.isDiscountPercentage ? 1 : 0),
        },
        redeemStartDate: {required},
        redeemEndDate: {required},
        voucherExpiryDate: {
          required: requiredIf(() => this.isDiscountPercentage),
        },
        redeemStartTime: {required},
        redeemEndTime: {required},
      },
    }
  }

  @Watch('voucherDetail')
  onPNDetailChanged(val: PublicVoucher): void {
    const formatDateCompare = 'DD-MM-YYYY'
    const formatTimeCompare = 'hh:mm'

    this.form = {
      voucherName: val.name || '',
      voucherCode: val.code || '',
      voucherLimit: val.limitUsagePerVoucher || 0,
      voucherPerUser: val.limitUsagePerUser || 0,
      voucherPurpose:
        constantData.VOUCHER_PURPOSES_EDIT.find(
          (item) => item.value === val.purpose
        ) || undefined,
      voucherPointAmount: val.voucherData?.pointAmount || 0,
      discountPercentage: val.voucherPurposeData?.discountPercentage || 0,
      maxDiscountAmount: val.voucherPurposeData?.discountPercentageMaximumAmount || 0,
      minTransaction: val.voucherPurposeData?.discountPercentageMinimumTransaction || 0,
      shipmentTypes: val.voucherPurposeData?.shipmentType?.split(',') || [],
      voucherExpiry: 1,
      redeemStartDate: new Date(<string>val.startDate),
      redeemEndDate: new Date(<string>val.endDate),
      redeemStartTime: new Date(<string>val.startDate),
      redeemEndTime: new Date(<string>val.endDate),
      voucherExpiryDate: new Date(<string>val.voucherExpiredAt),
      voucherCodeType: val.multipleVoucherCount && val.multipleVoucherCount > 0 ? constantData.EnumVoucherCodeType.MULTIPLE : constantData.EnumVoucherCodeType.SINGLE,
      voucherCodeMultipleCount: val.multipleVoucherCount,
      voucherCodeMultipleType: val.multipleVoucherPartner && val.multipleVoucherPartner !== '' ? constantData.EnumVoucherCodeMultipleType.EXTERNAL : constantData.EnumVoucherCodeMultipleType.INTERNAL,
      voucherCodeMultiplePartner: [], // onPublicVoucherListPartner
    }
    this.multipleVoucherCodePartner = val.multipleVoucherPartner

    if (val.status === 'ACTIVE') {
      this.disabledDate = true
      this.disabledTime = true
    }

    if (Utils.formatDate(new Date().toLocaleString(), formatDateCompare) === Utils.formatDate(<string>val.startDate,formatDateCompare)) {
      this.disabledDate = true
    }

    if (Utils.formatDate(new Date().toLocaleString(), formatTimeCompare) === Utils.formatDate(<string>val.startDate, formatTimeCompare) && this.disabledDate) {
      this.disabledTime = true
    }
  }

  @Watch('controller.publicVoucherCodePartner')
  private onPublicVoucherListPartner(data: PublicVoucherCodeMultipleExternalPartner[]): void {
    if (data.length > 0) {
      const multipleVoucherCodePartner = data.map(v => {
        return {
          label: Utils.toCapitalize(v.partnerName),
          value: v.partnerCode
        }
      })
      const newData = multipleVoucherCodePartner.find(v => v.value === this.multipleVoucherCodePartner)

      const finalData = newData || { value: '', label: '' }
      this.multipleVoucherCodePartnerOptions = multipleVoucherCodePartner
      this.form.voucherCodeMultiplePartner = [finalData]
    }
  }

  @Watch('controller.isSuccessSave')
  onWatchUpdateSuccess(val: boolean): void {
    if (val) {
      this.successModal = true
    }
  }

  get isMultipleVoucher(): boolean {
    return this.form.voucherCodeType === constantData.EnumVoucherCodeType.MULTIPLE
  }

  get voucherDetail(): PublicVoucher {
    return controller.publicVoucherDetail
  }

  get isDiscountPercentage(): boolean {
    return this.form.voucherPurpose?.value === constantData.EnumVoucherPurpose.DISCOUNT_PERCENTAGE
  }

  get isPending(): boolean {
    return this.voucherDetail.status === 'PENDING'
  }

  get isActive(): boolean {
    return this.voucherDetail.status === 'ACTIVE'
  }

  get titleDateTime(): string {
    return this.isDiscountPercentage || !this.form.voucherPurpose ? 'Redeem' : ''
  }

  get isCancelledOrExpired(): boolean {
    return (
      this.voucherDetail.status === 'CANCELLED' ||
      this.voucherDetail.status === 'EXPIRED'
    )
  }

  get isParcelPoint(): boolean {
    return this.voucherDetail.purpose?.toUpperCase() === constantData.EnumVoucherPurpose.PARCEL_POINT
  }

  private onRedeemStartDateChange(date: Date): void {
    this.form.redeemStartDate = date
    this.hasChanged.redeemStartDate = true
  }

  private onRedeemStartTimeChange(date: Date): void {
    this.form.redeemStartTime = date
    this.hasChanged.redeemStartTime = true
  }

  private onRedeemEndDateChange(date: Date): void {
    this.form.redeemEndDate = date
    this.hasChanged.redeemEndDate = true
  }

  private onRedeemEndTimeChange(date: Date): void {
    this.form.redeemEndTime = date
    this.hasChanged.redeemEndTime = true
  }

  private onVoucherExpireChange(date: Date): void {
    this.form.voucherExpiryDate = date
    this.hasChanged.voucherExpiryDate = true
  }


  private setAllChanged(): void {
    this.hasChanged = {
      voucherName: true,
      voucherCode: true,
      voucherLimit: true,
      voucherPerUser: true,
      voucherPurpose: true,
      voucherPointAmount: true,
      discountPercentage: true,
      maxDiscountAmount: true,
      minTransaction: true,
      shipmentTypes: true,
      voucherExpiry: true,
      redeemStartDate: true,
      redeemEndDate: true,
      voucherExpiryDate: true,
      redeemStartTime: true,
      redeemEndTime: true,
    }
  }

  private setShipmentTypes(value: string): void {
    if (this.form.shipmentTypes.includes(value)) {
      this.form.shipmentTypes = this.form.shipmentTypes.filter(item => item !== value)
    } else {
      this.form.shipmentTypes.push(value)
    }
  }

  private onUpdateVoucher(): void {
    this.setAllChanged()
    this.confirmationModal = false
    if (!this.$v.form.$invalid) {
      const startDateTime = Utils.setFormatDateTime(
        <Date>this.form.redeemStartDate,
        (<Date>this.form.redeemStartTime).getHours(),
        (<Date>this.form.redeemStartTime).getMinutes()
      )
      const endDateTime = Utils.setFormatDateTime(
        <Date>this.form.redeemEndDate,
        (<Date>this.form.redeemEndTime).getHours(),
        (<Date>this.form.redeemEndTime).getMinutes()
      )
      const expiryDateTime = !this.isDiscountPercentage ? '' : Utils.setFormatDateTime(
        <Date>this.form.voucherExpiryDate,
        23,
        59,
        59
      )

      const formatDateTime = 'YYYY-MM-DDTHH:mm:ssZ'

      controller.updatePublicVoucher({
        publicVoucherId: this.$route.params.id,
        voucherLimit: this.form.voucherLimit,
        voucherPerUser: this.form.voucherPerUser,
        voucherPurpose: this.form.voucherPurpose,
        voucherPointAmount: this.form.voucherPointAmount,
        discountPercentage: this.form.discountPercentage,
        maxDiscountAmount: this.form.maxDiscountAmount,
        minTransaction: this.form.minTransaction,
        shipmentTypes: this.form.shipmentTypes,
        voucherExpiry: this.form.voucherExpiry,
        redeemStartDateTime: Utils.formatDate(startDateTime, formatDateTime),
        redeemEndDateTime: Utils.formatDate(endDateTime, formatDateTime),
        voucherExpiryDateTime: Utils.formatDate(expiryDateTime, formatDateTime),
      })
    } else {
      this.$notify({
        title: 'Invalid Edit Public Voucher',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({
      name: 'PublicVoucherDetailPage',
      params: {id: this.$route.params.id},
    })
  }

  get hourOptions(): Array<string | number> {
    return Array.from({length: 24}, (_x, i) => i)
  }

  get statusBadgeType(): string {
    if (
      this.voucherDetail?.status?.toLowerCase() === 'expired' ||
      this.voucherDetail?.status?.toLowerCase() === 'cancelled'
    ) {
      return 'error'
    } else if (
      this.voucherDetail?.status?.toLowerCase() === 'active' ||
      this.voucherDetail?.status?.toLowerCase() === 'published'
    ) {
      return 'success'
    } else {
      return 'warning'
    }
  }

  get getVoucherItemStatus(): string {
    return Utils.toCapitalize(this.voucherDetail.status || '-')
  }

  beforeDestroy(): void {
    controller.setSuccessSave(false)
  }
}
